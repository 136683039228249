
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-staffsales-red: (
    50 : #fae3e4,
    100 : #f3babc,
    200 : #eb8c90,
    300 : #e35e64,
    400 : #dd3c42,
    500 : #d71921,
    600 : #d3161d,
    700 : #cd1218,
    800 : #c70e14,
    900 : #be080b,
    A100 : #ffe8e8,
    A200 : #ffb5b6,
    A400 : #ff8283,
    A700 : #ff696a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-staffsales-grey: (
  50 : #eaeeef,
  100 : #ccd4d7,
  200 : #aab7bd,
  300 : #879aa2,
  400 : #6e848e,
  500 : #546e7a,
  600 : #4d6672,
  700 : #435b67,
  800 : #3a515d,
  900 : #293f4a,
  A100 : #58e7ff,
  A200 : #1bdeff,
  A400 : #02daff,
  A700 : #00cef1,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$staff-sales-primary: mat-palette($mat-staffsales-red, 500);
$staff-sales-accent: mat-palette($mat-staffsales-grey, 500);

// The warn palette is optional (defaults to red).
$staff-sales-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$staff-sales-theme: mat-light-theme((
  color: (
    primary: $staff-sales-primary,
    accent: $staff-sales-accent,
    warn: $staff-sales-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($staff-sales-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif;
  //background-image: linear-gradient(125deg, #151143 0%, #4267b2 53%, #d073e0 120%);
  //background-image: linear-gradient(125deg, #13103a 0%, #2f61c6 53%, #e76ffc 120%);
}

.error {
	background-color: #c8171f !important;
}

.success {
	background-color: #28b463 !important;
}